import React, {useState} from "react";
import Layout from "../components/layout";
import {StaticImage} from "gatsby-plugin-image"

import {CompanyHistory} from "../components/company-history";
import get from "lodash/get";
import SEO from "../components/seo";
import {graphql} from "gatsby";

const link = 'https://www.chinalandlink.com/en/images/about_banner.jpg'

const AboutUs = ({location, data}) => {
  const allKeywords = get(data, 'allContentfulPageKeywords.nodes')
  const allAssets = get(data, 'allContentfulAsset.nodes')

  const findImage = (title) => allAssets.find(x => x.title === title)

  return (
    <Layout location={location}>
      <SEO title={'معلومات عنا'} keywords={allKeywords[0]?.keywords}/>
      <img src={link} className="img-fluid" alt="..." style={{minHeight: '10rem'}}/>
      <div className="container">
        <div className='my-5'>
          <h1 className='py-3 text-center'>للأجهزة المنزلية، لديك هوم بيست</h1>
          <p className='p'>تاسست شركة نينغبو لاندلينك التقنية الصناعية االمحدودة  في عام 1999، هو خبير في المستلزمات الكهربائية المنزلية ومورد متكامل لسلسلة التوريد، متخصص في المفاتيح والمقابس وإضاءة الصمام الثنائي الباعث للضوء.</p>
          <StaticImage src="../../static/images/about-1.png" alt="About campony"/>
        </div>
        <div className='my-5'>
          <h1 className='py-3 text-center'>20 عامًا من الخبرة في إنتاج الأجهزة المنزلية</h1>
          <CompanyHistory lang='ar'/>
        </div>
        <div className='my-5'>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3 className='py-3'>التوجه نحو السوق</h3>
                <p>من خلال التحقيقات في الموقع، وزيارة العملاء والأساليب الأخرى، لدينا فهم عميق لاتجاه السوق المحلي واتجاه الاستهلاك، وتطوير منتجات جديدة موجهة نحو السوق للعملاء. في نفس الوقت، من خلال الفهم العميق لمعايير السوق المحلية، بما في ذلك IEC و CE و RoHS و SASO و ESMA وما إلى ذلك، يمكننا ضمان أن منتجاتنا يمكن أن تتوافق مع المتطلبات القياسية.</p>
              </div>
              <div className="col-6">
                <h3 className='py-3'>ضمان التوريد</h3>
                <p>لدينا ثلاث قواعد إنتاج وتصنيع كبيرة لضمان إنتاج الكيان لخطوط الإنتاج الثلاثة الرئيسية للمفاتيح والمقابس وإضاءة الصمام الثنائي الباعث للضوء.  تقع القواعد في "يانغتسي نهر دلتا" و "اللؤلؤ نهر دلتا"، بجوار السلسلة الصناعية.  من خلال شبكة التوريد التي تضم أكثر من 800 مورد، نحن قادرون على تصميم وإدارة مخططات التسليم المتكاملة وفقًا لمتطلبات العملاء، وتوفير إدارة وخدمات سلسلة التوريد من حيث تصميم المنتج والإنتاج والتصنيع ومراقبة الجودة، ونسعى جاهدين لتوفير  عملاء يتمتعون بأمن سلسلة توريد عالي الجودة ورخيص الثمن ودقيق.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <h3 className='py-3'>حسن النية</h3>
                <p>بصفتنا شركة جديرة بالثقة، فإننا ندير أعمالًا صادقة، ونتمتع بسمعة طيبة في الصناعة، وقد أنشائنا علاقات تعاون واسعة ومتعمقة مع العديد من العملاء في الشرق الأوسط وإفريقيا وبريطانيا ودول ومناطق أخرى. نولي أهمية كبرى لتقييم عملائنا ورضاهم.</p>
              </div>
              <div className="col-6">
                <h3 className='py-3'>التعاون المربح للجانبين</h3>
                <p>من خلال فريق محترف وفعال ومتعاون، نشارك بنشاط في كل من أعمال عملائنا، ونفهم وندعم إستراتيجية التسويق الخاصة بهم، ونحاول التحول من مورد عادي إلى شريكهم الاستراتيجي. من خلال التعاون الممتاز، سنحقق حقًا عائدًا في الأداء لعملائنا وشركائنا، ونعمل بشكل مشترك على تعزيز الانسجام في المجتمع.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='my-5'>
          <h1 className='py-3 text-center'>نمتلك 2 مصانع و 8 خطوط إنتاج</h1>
          <p className='pb-3'>تأسست شركة هوم بيست لتصنيع أجهزة منزلية ذات جودة أفضل. بعد 20 عامًا من تصدير عشرات المليارات من المنتجات، نحن نغير الصناعة ونضمن جودة كل منتج. يدعم مصنعنا المتطور كل هذا. مع سنوات من الخبرة في الإنتاج والتصميم والاختبار والتصنيع والمبيعات والتسويق والتصميم، تتدفق مواهب فريقنا في كل مكون وكل منتج.</p>
          <div className="container">
            <div className="row py-3">
              <div className="col-4">
                <img src={findImage("Factory equipment 1").fluid.src} alt={findImage("Factory equipment 1").title}/>
              </div>
              <div className="col-4">
                <img src={findImage("Factory equipment 2").fluid.src} alt={findImage("Factory equipment 2").title}/>
              </div>
              <div className="col-4">
                <img src={findImage("Factory equipment 3").fluid.src} alt={findImage("Factory equipment 3").title}/>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-7">
                <p>
                  معمل تصميم مستقل
                  <br/>
                  مهندس ذو خبرة
                  <br/>
                  تقنية مقاومة الشحن الزائد براعة تصميم رائعة
                  <br/>
                  لقد سمحت لنا سنوات عديدة من الخبرة في الإنتاج والتصدير بالحصول على سوق معين
                  <br/>
                  على نطاق واسع في الشرق الأوسط وجنوب أفريقيا وجنوب شرق آسيا..
                </p>
              </div>
              <div className="col-5">
                <StaticImage src="../../static/images/product-line-people.png" alt="About campony"/>
              </div>
            </div>
          </div>
        </div>
        <div className='my-5'>
          <h1 className='py-3 text-center'>نحن مصنع حقيقي</h1>
          <p className='pb-3'>يضم فريقنا خبراء تقنيين ومديري إنتاج ومديري مراقبة الجودة وتجار ومتخصصين في التصنيع الذين يؤمنون بما يلي:</p>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <StaticImage src="../../static/images/manager-group.png" alt="About campony"/>
              </div>
            </div>
            <div className="row py-4">
              <div className="col-3">
                <StaticImage src="../../static/images/manager-1.png" alt="About campony"/>
              </div>
              <div className="col-3">
                <StaticImage src="../../static/images/manager-2.png" alt="About campony"/>
              </div>
              <div className="col-3">
                <StaticImage src="../../static/images/manager-3.png" alt="About campony"/>
              </div>
              <div className="col-3">
                <StaticImage src="../../static/images/manager-4.png" alt="About campony"/>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-7">
                <h1 className='py-3'>HB موجد  للسماح لمزيد من الأشخاص برؤيتنا عبر الإنترنت</h1>
                <p> في عام 2017، تم بيعها بشكل أساسي في نيجيريا والكويت والمملكة العربية السعودية والبحرين وأسواق أخرى في الشرق الأوسط، بلغت المبيعات أكثر من 10 مليون دولار في عام 2019، وحققت في عام 2020 زيادة بنسبة 50٪ مقارنة بعام 2019. انقر هنا وشاهد المزيد عن هوم بيست .</p>
              </div>
              <div className="col-5">
                <StaticImage src="../../static/images/let-me-see-you-online.png" alt="About campony"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query ABoutUsARQuery {
        allContentfulPageKeywords(filter: {page: {eq: "aboutus"}, node_locale: {eq: "ar"}}) {
            nodes {
                keywords
                node_locale
                page
            }
        }
        allContentfulAsset( filter: {title: {in: ["Factory appearance", "Product line worker", "Factory equipment 1", "Factory equipment 2", "Factory equipment 3"]}, node_locale: {eq: "ar"}})
        {
            nodes {
                fluid(quality: 90, maxWidth: 1920) {
                    src
                }
                title
            }
        }
    }
`
export default AboutUs;
