import React, {useState} from "react";
import {LeftArrowCircleIcon, RightArrowCircleIcon} from "../icons";

import * as styles from './company-history.module.css'

const HISTORY_MAP = [
  {
    en: '1999 Ningbo Youkai International Trade Co., Ltd. was established (the predecessor of Ningbo LANGDLINK Industrial Co., Ltd.)',
    zh: '1999年 宁波尤凯国际贸易有限公司成立（宁波汉峰(LANGDLINK)工业科技有限公司前身）',
    ar: 'في عام 1999 شركة نينغبو يوكاي التجارية الدولية المحدودة. تم انشائها (سلفا لشركة نينغبو لاندلينك الصناعية المحدودة)'
  },
  {
    en: '2009 The company established its own production bases in Ningbo and Zhongshan',
    zh: '2009年 建立宁波、中山自有生产基地',
    ar: 'في عام 2009 أنشأت الشركة قواعد الإنتاج الخاصة بها في نينغبو وتشونغشان'
  },
  {
    en: '2011 The company\'s export exceeded 30 million US dollars, and its imperial-standard electrical accessories export exceeded 15 million US dollars',
    zh: '2011年 公司出口额超3千万，英制电器附件出口超1500万',
    ar: 'في عام 2011 تجاوزت صادرات الشركة 30 مليون دولار أمريكي، وتجاوزت صادراتها من المستلزمات الكهربائية ذات المعايير الإمبراطورية 15 مليون دولار أمريكي'
  },
  {
    en: '2015  The company was moved to Smart and Technology Parks\n The Ningbo production base was expanded, listed among the top \n500 Export Companies in Ningbo',
    zh: '2015年 公司搬迁至智慧谷, 宁波生产基地扩建，跻身宁波出口500强',
    ar: 'في عام 2015 تم نقل الشركة إلى مجمعات ذكية وتقنية. تم توسيع قاعدة إنتاج نينغبو، حيث تم إدراجها بين الأعلى 500 شركة تصدير في نينغبو.'
  },
  {
    en: '2017  Won Economic Development Award from Government, registered self-owned brand HOMEBEST',
    zh: '2017年  获得经济发展奖，注册自主品牌HOMEBEST',
    ar: 'في عام 2017 فازت بجائزة التنمية الاقتصادية من الحكومة، وسجلت علامة تجارية خاصة بها هوم بيست'
  },
  {
    en: '2021 Established an e-commerce company to promote the self-owned brand HOMEBEST',
    zh: '2021年  成立电商公司，推广自主品牌HOMEBEST',
    ar: 'في عام 2021، تم إنشاء شركة للتجارة الإلكترونية للترويج للعلامة التجارية الخاصة بها هوم بيست'
  }
]

export const CompanyHistory = ({lang}) => {
  const [ currentIndex, setCurrentIndex ] = useState(0)
  const handleClick = (value) => {
    const tempValue = currentIndex + value
    const nextValue = tempValue > 5 ? 0 : (tempValue < 0 ? 5 : tempValue)
    setCurrentIndex(nextValue)
  }
  return (
    <div className="p-5">
      <div className="section5 flex-col pb-5">
        <div className="mod1 flex-row">
          <span className="word9" onClick={() => setCurrentIndex(0)}>1999</span>
        </div>
        <div className="mod2 flex-row">
          <span className="word10" onClick={() => setCurrentIndex(1)}>2009</span>
          <span className="word11" onClick={() => setCurrentIndex(2)}>2011</span>
          <span className="txt2" onClick={() => setCurrentIndex(3)}>2015</span>
          <span className="word12" onClick={() => setCurrentIndex(4)}>2017</span>
        </div>
        <div className="mod3 flex-row"><span className="word13" onClick={() => setCurrentIndex(5)}>2021</span></div>
      </div>
      <div className="section7 flex-row">
        <div className={styles.arrow}>
          <button onClick={() => handleClick(-1)}>
            <LeftArrowCircleIcon />
          </button>
          <button onClick={() => handleClick(1)}>
            <RightArrowCircleIcon />
          </button>
        </div>
        <div>
          <p>
            { lang !== 'ar' && <span className="word15">{HISTORY_MAP[currentIndex].zh}</span> }
          </p>
          <p>
            <span className="word15">{HISTORY_MAP[currentIndex][lang]}</span>
          </p>
        </div>
      </div>
      <div className={'py-5'}/>
    </div>
  )
}